import { render, staticRenderFns } from "./UnstoppableAddOwnedDomainOverlay.vue?vue&type=template&id=6e32e2a4&scoped=true&"
import script from "./UnstoppableAddOwnedDomainOverlay.vue?vue&type=script&lang=js&"
export * from "./UnstoppableAddOwnedDomainOverlay.vue?vue&type=script&lang=js&"
import style0 from "./UnstoppableAddOwnedDomainOverlay.vue?vue&type=style&index=0&id=6e32e2a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e32e2a4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/XDCWallet/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6e32e2a4')) {
      api.createRecord('6e32e2a4', component.options)
    } else {
      api.reload('6e32e2a4', component.options)
    }
    module.hot.accept("./UnstoppableAddOwnedDomainOverlay.vue?vue&type=template&id=6e32e2a4&scoped=true&", function () {
      api.rerender('6e32e2a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/dapps/unstoppable-domains-dapp/components/UnstoppableAddOwnedDomainOverlay.vue"
export default component.exports